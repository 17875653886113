import { API } from "@js/api";
import type { EmployerOffer, FreelancerOffer } from "@js/types/jobs";

import type {
  AcceptJobOfferParams,
  CreateOfferDataReturnData,
  OfferFormDataPayload,
  RejectJobOfferParams,
  UpdateOfferDataReturnData,
} from "./types";

export const offersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployerOffer: build.query<EmployerOffer, EmployerOffer["id"]>({
      query: (id) => ({
        url: `/employer_offers/${id}/`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "EmployerOffers", id: arg },
      ],
    }),
    createEmployerOffer: build.mutation<
      CreateOfferDataReturnData,
      OfferFormDataPayload
    >({
      query: (data) => ({
        url: "/employer_offers/",
        method: "POST",
        data,
      }),
      invalidatesTags: [
        { type: "EmployerOffers", id: "LIST" },
        { type: "EmployerBids" },
      ],
    }),
    updateEmployerOffer: build.mutation<
      UpdateOfferDataReturnData,
      { id: EmployerOffer["id"]; data: OfferFormDataPayload }
    >({
      query: ({ id, data }) => ({
        url: `/employer_offers/${id}/`,
        method: "PUT",
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerOffers", id: arg.id },
        { type: "EmployerBids" },
        "ManagedEmployers",
      ],
    }),
    cancelEmployerOffer: build.mutation<void, EmployerOffer["id"]>({
      query: (id) => ({
        url: `/employer_offers/${id}/cancel_offer/`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: "EmployerOffers", id: arg },
        { type: "EmployerBids" },
        "ManagedEmployers",
      ],
    }),
    getFreelancerOffer: build.query<FreelancerOffer, FreelancerOffer["id"]>({
      query: (id) => ({
        url: `/freelancer_offers/${id}/`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "FreelancerOffers", id: arg },
      ],
    }),
    rejectJobOffer: build.mutation<void, RejectJobOfferParams>({
      query: ({ id, ...data }) => ({
        url: `/freelancer_offers/${id}/reject_offer/`,
        method: "POST",
        data,
      }),
    }),
    acceptJobOffer: build.mutation<void, AcceptJobOfferParams>({
      query: ({ id, ...data }) => ({
        url: `/freelancer_offers/${id}/accept_offer/`,
        method: "POST",
        data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          // do not update optimistically so the offer page is not rerendered while the accept query is pending
          dispatch(
            offersApi.util.updateQueryData(
              "getFreelancerOffer",
              arg.id,
              (freelancerOffer) => {
                freelancerOffer.status = "accepted";
              },
            ),
          );
        } catch (e) {
          void e;
        }
      },
      invalidatesTags: (_result, error, arg) =>
        error ? [] : [{ type: "FreelancerOffers", id: arg.id }],
    }),
  }),
});

export const {
  useGetEmployerOfferQuery,
  useCreateEmployerOfferMutation,
  useUpdateEmployerOfferMutation,
  useCancelEmployerOfferMutation,
  useGetFreelancerOfferQuery,
  useRejectJobOfferMutation,
  useAcceptJobOfferMutation,
} = offersApi;

export const useGetEmployerOfferQueryState =
  offersApi.endpoints.getEmployerOffer.useQueryState;
