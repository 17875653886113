import React from "react";
import _ from "underscore";

import { useManagedEmployer } from "@js/apps/employer/hooks";
import type { UpdateJobFeedbackParams } from "@js/apps/jobs/actions";
import { updateJobFeedback } from "@js/apps/jobs/actions";
import { RouterLink } from "@js/components/link";
import { ModalConfirm, openModalAndWaitForInput } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type {
  EmployerOwnJob,
  Job,
  NodeStaffMainJobListingJob,
} from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { useCompleteJobMutation } from "../../api";
import type { CloseJobFormData } from "../../forms/close-job";
import {
  CloseFilledJobForm,
  CloseJobForm,
  UNSURE_CLIENT_UNREACHABLE,
} from "../../forms/close-job";
import { CloseNotFilledJobForm } from "../../forms/close-job/close-not-filled-job-form";

const STEP = {
  CLOSE_JOB_FORM_MODAL_OPENED: "CLOSE_JOB_FORM_MODAL_OPENED",
  NOT_FILLED_JOB_MODAL_OPENED: "NOT_FILLED_JOB_MODAL_OPENED",
  FILLED_JOB_MODAL_OPENED: "FILLED_JOB_MODAL_OPENED",
} as const;

type StepType = EnumType<typeof STEP>;

const useCloseJob = ({
  job,
  onSuccess,
}: {
  job: Job | EmployerOwnJob | NodeStaffMainJobListingJob;
  onSuccess: () => void;
}) => {
  const [completeJob] = useCompleteJobMutation();
  const [step, setStepObject] = React.useState<{
    current: StepType;
    prev: StepType | null;
  }>({
    current: STEP.CLOSE_JOB_FORM_MODAL_OPENED,
    prev: null,
  });
  const { refetch: refetchEmployerProfile } = useManagedEmployer();

  const [isJobClosed, setIsJobClosed] = React.useState<boolean>(false);

  const setStep = (newStep: StepType) => {
    setStepObject({
      current: newStep,
      prev: step.current,
    });
  };

  const jobId = job.id;

  const _onSuccess = () => {
    onSuccess();
    openSuccessToast();
  };

  const sendJobFeedback = async (clientFeedback: UpdateJobFeedbackParams) => {
    if (!_.isEmpty(clientFeedback)) {
      const notNullData = Object.fromEntries(
        Object.entries(clientFeedback).filter(([, value]) => value !== null),
      );

      await updateJobFeedback(jobId, notNullData as UpdateJobFeedbackParams);
    }
  };

  const handleFirstStepSubmit = async (values: CloseJobFormData) => {
    if (values.client_feedback_was_job_filled === UNSURE_CLIENT_UNREACHABLE) {
      try {
        await openModalAndWaitForInput({
          children: (
            <ModalConfirm confirmText="Close job" cancelText="Cancel">
              Are you sure you want to close this job?
            </ModalConfirm>
          ),
        });
        await completeJob({
          jobId,
          data: { client_feedback_was_job_filled: null },
        }).unwrap();
        _onSuccess();
      } catch (error) {
        // user cancelled
      }
    } else if (values.client_feedback_was_job_filled) {
      setStep(STEP.FILLED_JOB_MODAL_OPENED);
    } else {
      setStep(STEP.NOT_FILLED_JOB_MODAL_OPENED);
    }
  };

  const handleFeedbackFilledSubmit = async (
    clientFeedback: UpdateJobFeedbackParams,
  ) => {
    try {
      if (!isJobClosed) {
        await completeJob({
          jobId,
          data: { client_feedback_was_job_filled: true },
        }).unwrap();
        setIsJobClosed(true);
      } else {
        // In case job is closed, we need to refetch manually as we are not doing completeJob mutation
        refetchEmployerProfile();
      }
      await sendJobFeedback(clientFeedback);
      _onSuccess();
    } catch (error) {
      Snackbar.error("Job completion failed!");
    }
  };

  const handleFeedbackNotFilledSubmit = async (
    clientFeedback: UpdateJobFeedbackParams,
  ) => {
    try {
      if (!isJobClosed) {
        await completeJob({
          jobId,
          data: {
            client_feedback_was_job_filled: false,
          },
        }).unwrap();
        setIsJobClosed(true);
      } else {
        // In case job is closed, we need to refetch manually as we are not doing completeJob mutation
        refetchEmployerProfile();
      }
      await sendJobFeedback(clientFeedback);
      _onSuccess();
    } catch (error) {
      Snackbar.error("Job completion failed!");
    }
  };

  const prevStep = step.prev;
  const currentStep = step.current;
  const firstStepInitialValues = React.useMemo(() => {
    const userCameBackFromDifferentStep = prevStep !== null;
    const userCameBackFromFilledStep =
      userCameBackFromDifferentStep &&
      prevStep === STEP.FILLED_JOB_MODAL_OPENED;
    return {
      client_feedback_was_job_filled: userCameBackFromDifferentStep
        ? userCameBackFromFilledStep
        : null,
    };
  }, [prevStep]);

  return {
    onSuccess,
    handleFirstStepSubmit,
    handleFeedbackFilledSubmit,
    handleFeedbackNotFilledSubmit,
    currentStep,
    prevStep,
    setStep,
    firstStepInitialValues,
  };
};

export const CloseJobModalManager = ({
  modalInstance,
  afterAction,
  job,
}: {
  modalInstance: { close: () => void };
  afterAction: (job: Job | EmployerOwnJob | NodeStaffMainJobListingJob) => void;
  job: Job | EmployerOwnJob | NodeStaffMainJobListingJob;
}) => {
  const {
    handleFirstStepSubmit,
    handleFeedbackFilledSubmit,
    handleFeedbackNotFilledSubmit,
    currentStep,
    setStep,
    firstStepInitialValues,
  } = useCloseJob({
    job,
    onSuccess: () => {
      afterAction(job);
      modalInstance.close();
    },
  });

  switch (currentStep) {
    case STEP.CLOSE_JOB_FORM_MODAL_OPENED: {
      return (
        <CloseJobForm
          onSubmit={handleFirstStepSubmit}
          initialValues={firstStepInitialValues}
          onCancel={modalInstance.close}
        />
      );
    }
    case STEP.FILLED_JOB_MODAL_OPENED: {
      return (
        <CloseFilledJobForm
          onSubmit={handleFeedbackFilledSubmit}
          onCancelClick={() => {
            setStep(STEP.CLOSE_JOB_FORM_MODAL_OPENED);
          }}
        />
      );
    }
    case STEP.NOT_FILLED_JOB_MODAL_OPENED: {
      return (
        <CloseNotFilledJobForm
          onSubmit={handleFeedbackNotFilledSubmit}
          onCancelClick={() => {
            setStep(STEP.CLOSE_JOB_FORM_MODAL_OPENED);
          }}
        />
      );
    }
    default: {
      assertUnreachable(currentStep);
      return null;
    }
  }
};

export const openSuccessToast = () =>
  Snackbar.toast({
    header: "This job is now closed.",
    content: (closeSnackbar) => {
      return (
        <>
          It has been removed from the Braintrust Talent job search. If you need
          to post this job again, head over to the{" "}
          <RouterLink
            onClick={() => {
              closeSnackbar();
            }}
            style={{ textDecoration: "underline" }}
            to="/jobs/add_new"
          >
            Post Job
          </RouterLink>{" "}
          page and create a new posting.
        </>
      );
    },
  });
