import { useCallback, useEffect, useMemo } from "react";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import { updateVisitsCounter } from "@js/apps/common/actions";
import { useGetEmployerPublicProfileQuery } from "@js/apps/employer/api";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { useNavigate } from "@js/hooks/";
import { useIdParam } from "@js/hooks/use-id-param";
import { parseFormSubmitError } from "@js/utils";

import {
  useAcceptJobOfferMutation,
  useGetFreelancerOfferQuery,
} from "../../api";
import type { AcceptOfferFormData } from "../../forms";
import { openRejectOfferModal } from "../../forms";

export const useTalentOffer = () => {
  const navigate = useNavigate();
  const jobId = useIdParam();
  const offerId = useIdParam("offerId");

  const [acceptJobOffer] = useAcceptJobOfferMutation();
  const { data: offer, isLoading: isOfferLoading } = useGetFreelancerOfferQuery(
    offerId ?? skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { data: job, isLoading: isJobLoading } = useGetJobQuery(
    jobId ? { id: jobId } : skipToken,
  );
  const { data: employerProfile, isFetching: fetchingEmployerPublicProfile } =
    useGetEmployerPublicProfileQuery(job?.employer.id ?? skipToken);

  useEffect(() => {
    if (!job || !offer) {
      return;
    }

    const isOfferMatchingJob = offer.job.id === job.id;
    if (!isOfferMatchingJob) {
      navigate("/page-not-found/", { replace: true });
    }
  }, [job, offer, navigate]);

  useEffect(() => {
    if (!offerId) {
      return;
    }

    updateVisitsCounter(offerId, ENUMS.VisitsCounterType.OFFER);
  }, [offerId]);

  const onSubmit = async (values: AcceptOfferFormData) => {
    if (!offer) {
      return;
    }

    try {
      await acceptJobOffer({
        id: offer.id,
        checksum: offer.checksum,
        address: values.address,
      }).unwrap();

      navigate(`/jobs/${jobId}/offers/${offer?.id}/accepted/`);
    } catch (error) {
      throw new SubmissionError(
        parseFormSubmitError({
          error,
          defaultGeneralFormError: "Something went wrong",
        }),
      );
    }
  };

  const onSubmitFail = (errors: Record<string, string>) => {
    if (errors._error) {
      Snackbar.error(errors._error);
    }
  };

  const initialValues = useMemo(() => {
    return {
      address: offer?.shipping_address,
    };
  }, [offer]);

  const onOfferReject = useCallback(() => {
    if (!offer) {
      return;
    }

    openRejectOfferModal({ offer });
  }, [offer]);

  const isLoading =
    isJobLoading || isOfferLoading || fetchingEmployerPublicProfile;

  return {
    loading: isLoading,
    offer,
    offerId: Number(offerId),
    job,
    employer: employerProfile,
    onOfferReject,
    onSubmit,
    onSubmitFail,
    initialValues,
  };
};
