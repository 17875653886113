import type { ReactNode } from "react";

import type { PrimitiveValue } from "@hexocean/braintrust-ui-components";
import type { IsoDateOrIsoDateTime } from "@js/utils/date/types";

import type { Skill } from "./admin";
import type { PublicUser } from "./auth";

export type Balance = {
  id: number;
  available_tokens: string;
  unvested_tokens: string;
  tokens_via_referrals: string;
  tokens_via_community: string;
  escrow_tokens: string;
  token_lifetime_earnings: string;
  tokens_via_others: string;
  safe_wallet_address: string;
};

export type CommonState = {
  appLayoutKey: number;
  searchResults: Array<any>;
  projectCategories: Array<any>;
  projectSubcategories: Array<any>;
  industries: Array<any>;
  fetchingCommonSkills: boolean;
  commonSkills: Record<number, Skill[]>;
  roleTypeSkills: Record<number, Skill[]>;
  fetchingBalance: boolean;
  showLeftSidebar: boolean;
  layoutBgColor?: string;
  fetchingRoleTypeSkills: boolean;
  popularLocations: Array<PopularLocation>;
  stats: Partial<Stats>;
  fetchingStats: boolean;
  activeMessengerRoom: number | undefined;
  hideRequestLoader: boolean;
  showPulseAnimationForPosts: number[];
};

export type CompanyNodeBase = {
  name: string;
  slug: string;
};

export type CompanyNode = CompanyNodeBase & {
  business_address: Address;
  stripe_withdrawal_method?: number;
};

export type LinkMetadata = {
  url: string;
  title?: string;
  description?: string;
  image?: string;
  source?: "default" | "job" | "freelancer";
  id: string;
};

type AddressComponentType = {
  [key: number]: string;
};

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: Array<AddressComponentType>;
};

export type CustomLocation = {
  name: string | undefined;
  id: string;
  custom: boolean;
  formatted_address: string;
  session_token?: string;
  place_id?: string;
};

export type GoogleLocation = {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: Record<string, unknown>;
  place_id: string;
  session_token?: string;
  utc_offset: number;
};

export type FullLocation = CustomLocation | GoogleLocation;

export type PopularLocation = {
  place_id: string | null;
  custom_location: string | null;
  full_location: FullLocation | null;
  name: string | null;
};

export type Stats = {
  open_jobs_count: number;
  users_count: number;
  users_count_representation: string;
};

export type CommonLanguage = {
  label: string;
  value: number;
  code: string;
};

export type UserLanguage = {
  id: number;
  language: {
    id: number;
    name: string;
    code: string;
  };
  skill_level: string;
};

type Taxonomy = {
  id: number;
  is_default: boolean;
  name: string;
  order: number | null;
  matched: boolean;
  is_superpower: boolean;
  categories: string[];
};

export type Degree = Taxonomy;
export type School = Taxonomy;
export type Certificate = Taxonomy;
export type Issuer = Taxonomy;

export type Address = {
  id: number;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  country_name?: string | null;
  state?: string | null;
  zipcode?: string | null;
  text?: string | null;
};

type StarsBreakdown = {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
};

export type ProfileReviews = ProfileBaseReviews & {
  id: number;
  can_user_review_job: boolean;
  can_user_review_help_offer: boolean;
};

export type ProfileBaseReviews = {
  review_count: number;
  total_jobs: number | null;
  average_responsiveness: string | null;
  average_work_quality: string | null;
  stars_breakdown: StarsBreakdown;
  average_rating: string | null;
};

export type InternalTalentReview = {
  author: Pick<
    PublicUser,
    "avatar" | "avatar_thumbnail" | "gravatar" | "id" | "public_name" | "title"
  > & { has_avatar_set: boolean };
  created: IsoDateOrIsoDateTime;
  id: number;
  rating: number;
  text: string;
};

export type ExternalSite = {
  id: number;
  name: string;
  logo: {
    id: number;
    thumbnail: string;
  };
  placeholder: string;
};

export type FreelancerPublicExternalProfile = {
  id: number;
  site: ExternalSite;
  public_url: string;
};

export type FreelancerExternalProfile = {
  id: number;
  public_url: string | null;
} & Omit<ExternalSite, "id">;

export type SavedFilter<T> = {
  id: number;
  name: string;
  new_job_notification_enabled: boolean;
  filters: T;
};

export type TimezoneOffset = `${"+" | "-"}${number}`;

export enum PossibleFilters {
  ROLE = "Role",
  SKILLS = "Skills",
  RATE = "Rate",
  LOCATION = "Location",
  COMMITMENT = "Commitment",
  JOB_TYPE = "Job type",
  AVAILABILITY = "Availability",
  EXPERIENCE = "Experience",
  APPROVAL_STATUS = "Approval status",
  WORKING_TIMEZONE = "WORKING_TIMEZONE",
}

export type FilterAppliedPayload = {
  filter_type: PossibleFilters;
  filter_value: Record<string, unknown>;
  search_query_id?: number;
};

export type WithRecaptchaCode<T> = T & {
  "g-recaptcha-response"?: string;
};

export type OptionWithPrimitiveValue<
  T extends PrimitiveValue = PrimitiveValue,
> = {
  label: ReactNode;
  value: T;
};

export type BasicError = {
  status: number;
  data: {
    _error: string;
  };
};
